import React from "react"

import cn from "classnames"
import { useIntl } from "react-intl"

import CommunityCarousel from "../CommunityCarousel/CommunityCarousel"
import { CommunityCarouselItem } from "../CommunitySlide/CommunitySlide"
import Modal from "~/components/Modals/Modal"
import { cancelScrollOnClose } from "~/utils/modals"

import css from "./CommunityModal.module.scss"

interface PropTypes {
  onChange: (postId: string | null) => void
  posts?: CommunityCarouselItem[]
  initialPostId?: string | null
  awaitData?: boolean
  totalPostCount?: number
  onCanLoadMore?: () => void
}

function CommunityModal({
  onChange,
  initialPostId,
  posts,
  awaitData,
  totalPostCount,
  onCanLoadMore,
}: PropTypes) {
  const intl = useIntl()

  const hasPosts = Array.isArray(posts) && posts.length > 0

  initialPostId = posts?.some(post =>
    post.type === "comment"
      ? post.payload.uuid === initialPostId
      : post.payload.id === initialPostId
  )
    ? initialPostId
    : undefined

  const close = () => {
    cancelScrollOnClose()

    onChange(null)
  }

  return (
    <Modal open={hasPosts} onClose={close} mode="fixed" backgroundOpacity={0.8}>
      {(modalRef, isOpen) => (
        <div
          className={cn(css.modal, { [css.openedModal]: isOpen })}
          ref={modalRef}
        >
          {hasPosts && (
            <>
              <CommunityCarousel
                {...{ onChange, onCanLoadMore, awaitData, totalPostCount }}
                posts={posts as CommunityCarouselItem[]}
                postIndex={
                  typeof initialPostId === "undefined"
                    ? 0
                    : (posts as CommunityCarouselItem[]).findIndex(post =>
                        post.type == "comment"
                          ? post.payload.uuid === initialPostId
                          : post.payload.id === initialPostId
                      )
                }
              />
              <button
                className={css.close}
                onClick={close}
                title={intl.formatMessage({ id: "action:close" })}
              />
            </>
          )}
        </div>
      )}
    </Modal>
  )
}

export default CommunityModal
