import React, { useContext } from "react"
import { useEffect } from "react"

import { FormattedMessage, useIntl } from "react-intl"
import { useDispatch, useSelector } from "react-redux"
import { TransitionGroup } from "react-transition-group"

import CommunityFeedPost, {
  OnOpenCarouselProps,
} from "../CommunityFeedPost/CommunityFeedPost"
import { Body, Button, Container, Heading, Loader } from "~/components/ui"
import VisibilityTransition from "~/components/VisibilityTransition/VisibilityTransition"
import { ModalLoginContext } from "~/context/ModalLoginContext"
import { communityApi, useGetPostsQuery } from "~/state/api/community"
import {
  communitySelector,
  nextPage,
  onReceivePosts,
} from "~/state/modules/community"

import css from "./CommunityFeedPostList.module.scss"

interface PropTypes {
  onOpenCarousel: (arg: OnOpenCarouselProps) => void
  onClickOnComment: () => void
}

function CommunityFeedPostList(props: PropTypes) {
  const intl = useIntl()
  const dispatch = useDispatch()
  const { posts, nextCursor, nextUrl } = useSelector(communitySelector)
  const { refetch } = communityApi.endpoints.getPosts.useQuerySubscription({
    cursor: nextCursor || undefined,
  })
  const apiResult = useGetPostsQuery({
    cursor: nextCursor || undefined,
    locale: intl.locale,
  })

  const { isLogin } = useContext(ModalLoginContext)
  useEffect(() => {
    refetch()
  }, [isLogin])

  useEffect(() => {
    if (apiResult.data?.results && apiResult.isSuccess) {
      dispatch(onReceivePosts(apiResult.data))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiResult])

  const getNextPage = () => {
    dispatch(nextPage())
  }

  return (
    <Container>
      <Heading className={css.title} Tag={"h1"}>
        <FormattedMessage id="community/section:feed" />
      </Heading>

      <div className={css.feed}>
        <TransitionGroup>
          {posts.map(post => (
            <VisibilityTransition key={post.id} variant="fade" duration={1000}>
              <CommunityFeedPost
                onOpenCarousel={props.onOpenCarousel}
                post={post}
                onClickOnComment={props.onClickOnComment}
              />
            </VisibilityTransition>
          ))}
        </TransitionGroup>

        {apiResult.isError && !apiResult.isLoading && posts.length === 0 && (
          <Body align="center">
            <FormattedMessage id="community/text:error-when-loading-posts" />
          </Body>
        )}

        {apiResult.isLoading && (
          <div className={css.loader}>
            <Loader />
          </div>
        )}

        {nextUrl && (
          <Button
            className={css.loadMoreButton}
            disabled={apiResult.isLoading || apiResult.isFetching}
            variant="secondary"
            onClick={getNextPage}
          >
            <FormattedMessage id="community/action:load-more" />
          </Button>
        )}
      </div>
    </Container>
  )
}

export default CommunityFeedPostList
