import React, { CSSProperties } from "react"

import { useIntl } from "react-intl"

import LikeButton from "./LikeButton/LikeButton"
import { CommunityCarouselItem } from "~/components/CommunityCarouselModal"
import Link from "~/components/Link/Link"
import { Body, Col, Heading, HrefButton, ImageCard, Row } from "~/components/ui"
import useIsOnCompact from "~/hooks/useIsOnCompact"
import { CommunityPost } from "~/models/CommunityPost"
import { PartialRecipe } from "~/models/PartialRecipe"
import { getVerticalFromId } from "~/models/Vertical"

import css from "./CommunityFeedPost.module.scss"
interface CommunityPostTitleProps {
  recipe: PartialRecipe
}

function CommunityPostTitle({ recipe }: CommunityPostTitleProps) {
  return (
    <Heading className={css.postTitle} variant="small">
      <Link to="recipes/recipe" params={recipe}>
        {recipe.title}
      </Link>
    </Heading>
  )
}

export interface OnOpenCarouselProps {
  index: number
  recipeId: string
  communityPostId: string
  totalPostCount: number
  posts: CommunityCarouselItem[]
  awaitData?: boolean
}

interface PropTypes {
  post: CommunityPost
  onOpenCarousel: (arg: OnOpenCarouselProps) => void
  onClickOnComment: () => void
}

function CommunityFeedPost({
  post,
  onOpenCarousel,
  onClickOnComment,
}: PropTypes) {
  const intl = useIntl()
  const isCompact = useIsOnCompact()
  const { fromUser: user, recipe } = post
  const color = getVerticalFromId(recipe.vertical).color

  const handleClickOnImage = (openFirst: "recipe" | "reproduction") => {
    onOpenCarousel({
      index: openFirst === "recipe" ? 0 : 1,
      recipeId: recipe.id,
      communityPostId: post.id,
      totalPostCount: recipe.postsCount || 0,
      awaitData: true,
      posts: [
        { type: "recipe", payload: recipe },
        { type: "reproduction", payload: post },
      ],
    })
  }

  return post.attachments.length && post.attachments[0].mediaThumbnail ? (
    <article
      className={css.communityPost}
      style={
        {
          "--current-theme-color": color,
        } as CSSProperties
      }
    >
      <Row>
        <Col width={[12, 0]}>
          {isCompact && <CommunityPostTitle recipe={recipe} />}
        </Col>

        <Col width={[12, 6]}>
          <Row reverse={isCompact}>
            <Col width={6}>
              <ImageCard
                overlay={{
                  type: recipe.accessMode === "public" ? "branded" : "premium",
                }}
                onClick={() => handleClickOnImage("recipe")}
                imageProps={{
                  src: recipe.webCoverThumbnail,
                  alt: recipe.title,
                  title: recipe.title,
                }}
              />
            </Col>
            <Col width={6}>
              <ImageCard
                overlay={{
                  type: "reproduction",
                  payload: (recipe.postsCount || 0).toString(),
                }}
                onClick={() => handleClickOnImage("reproduction")}
                imageProps={{
                  src: post.attachments[0].mediaThumbnail,
                }}
              />
            </Col>
          </Row>
        </Col>

        <Col width={[12, 6]}>
          <div className={css.content}>
            {!isCompact && <CommunityPostTitle recipe={recipe} />}

            <LikeButton post={post} />

            {post.challengeId !== null && (
              <HrefButton
                className={css.challengeButton}
                color={recipe.vertical}
                size="small"
                uppercase={false}
              >
                <Body variant="body3" noMargin semiBold color="white">
                  {intl.formatMessage({ id: "text:challenge" })}
                </Body>
                <span className={css.challengeIcon} />
              </HrefButton>
            )}

            <Body variant="body3" className={css.message}>
              <span className={css.authorPrefix}>
                {intl.formatMessage({ id: "recipe/text:made-by" })}
              </span>
              {` `}
              <Link
                to={`profile/u`}
                params={{ id: user.username }}
                title={user.username}
                rel="nofollow"
              >
                <span className={css.author}>{user.username}</span>
              </Link>
              {` `}
              {post.message}
            </Body>

            <Body variant="body3" semiBold color="gray2">
              <span className={css.comment} onClick={onClickOnComment}>
                {intl.formatMessage({ id: "action:comment" })}
              </span>
            </Body>
          </div>
        </Col>
      </Row>
    </article>
  ) : null
}

export default CommunityFeedPost
