import React, { useEffect, useState } from "react"

import { useIntl } from "react-intl"

import withTemplate, { TemplateDefaultPropTypes } from "../withTemplate"
import CommunityFeedBanner from "./CommunityFeedBanner/CommunityFeedBanner"
import CommunityFeedIceCreamBanner from "./CommunityFeedIceCreamBanner/CommunityFeedIceCreamBanner"
import CommunityFeedModal, {
  ModalType,
} from "./CommunityFeedModal/CommunityFeedModal"
import { OnOpenCarouselProps } from "./CommunityFeedPost/CommunityFeedPost"
import CommunityFeedPostList from "./CommunityFeedPostList/CommunityFeedPostList"
import CommunityCarouselModal, {
  CommunityCarouselItem,
} from "~/components/CommunityCarouselModal"
import SEO from "~/components/Seo/Seo"
import { Container } from "~/components/ui"
import useIsClient from "~/hooks/useIsClient"
import { apiCommunityPostToPost } from "~/models/CommunityPost"
import { useGetPostsQuery } from "~/state/api/community"

import css from "./CommunityFeed.module.scss"

function CommunityFeed(props: TemplateDefaultPropTypes) {
  const intl = useIntl()
  const isClient = useIsClient()

  const [shouldShowIceCreamBanner, setShouldShowIceCreamBanner] =
    useState<boolean>(false)
  const [nextCursor, setNextCursor] = useState<string | null>(null)
  const [modalType, setModalType] = useState<ModalType | null>(null)
  const [carousel, setCarousel] = useState<OnOpenCarouselProps | null>(null)

  const apiResult = useGetPostsQuery(
    {
      recipe__uuid: carousel?.recipeId,
      cursor: nextCursor || undefined,
    },
    {
      skip: !carousel?.recipeId,
    }
  )

  useEffect(() => {
    const urlParams = new URL(location.href).searchParams

    if (urlParams.get("utm_campaign") === "QRglaces") {
      setShouldShowIceCreamBanner(true)
    }
  }, [])

  useEffect(() => {
    if (
      !apiResult.isSuccess ||
      apiResult.isFetching ||
      !apiResult.data?.results ||
      !carousel?.communityPostId
    )
      return

    const posts: CommunityCarouselItem[] = [...apiResult.data.results]
      .map(apiCommunityPostToPost)
      .filter(post => post.id !== carousel.communityPostId)
      .map(post => ({ type: "reproduction", payload: post }))

    setCarousel(prevData =>
      !prevData
        ? null
        : {
            ...prevData,
            awaitData: false,
            posts: [...prevData.posts, ...posts],
          }
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiResult])

  const loadMoreCarouselData = async () => {
    if (!carousel || !apiResult.data?.next) return

    const urlParams = new URLSearchParams(
      new URL(apiResult.data.next).searchParams
    )

    setNextCursor(urlParams.get("cursor"))
  }

  const closeModal = (id: string | null) => {
    if (id === null) {
      setCarousel(null)
      setNextCursor(null)
    }
  }

  const openCarouselModal = (arg: OnOpenCarouselProps) => setCarousel(arg)

  const handleOpenCommentModal = () => setModalType("comment")

  const handleOpenShareModal = () => setModalType("post")

  const handleCloseModal = () => setModalType(null)

  const getInitialPostId = (post: CommunityCarouselItem) => {
    if (post.type == "comment") {
      return post.payload.uuid
    }
    return post.payload.id
  }

  return (
    <>
      <SEO
        title={intl.formatMessage({
          id: "community/feed/text:seo-title",
        })}
        description={intl.formatMessage({
          id: "community/feed/text:seo-description",
        })}
        location={props.location}
      />

      <CommunityCarouselModal
        posts={carousel?.posts}
        initialPostId={
          carousel && getInitialPostId(carousel.posts[carousel.index])
        }
        onChange={closeModal}
        awaitData={carousel?.awaitData}
        totalPostCount={carousel?.totalPostCount && carousel.totalPostCount + 1}
        onCanLoadMore={loadMoreCarouselData}
      />

      <CommunityFeedModal type={modalType} onClose={handleCloseModal} />

      <Container
        style={{ visibility: isClient ? "visible" : "hidden" }}
        className={css.topBanner}
      >
        {shouldShowIceCreamBanner ? (
          <CommunityFeedIceCreamBanner />
        ) : (
          <CommunityFeedBanner onClick={handleOpenShareModal} />
        )}
      </Container>

      <CommunityFeedPostList
        onOpenCarousel={openCarouselModal}
        onClickOnComment={handleOpenCommentModal}
      />
    </>
  )
}

export default withTemplate(CommunityFeed)
