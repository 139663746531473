import React from "react"

import { StaticImage } from "gatsby-plugin-image"

import useIsOnCompact from "~/hooks/useIsOnCompact"

import css from "./CommunityFeedIceCreamBanner.module.scss"

function CommunityFeedIceCreamBanner() {
  const isCompact = useIsOnCompact()

  const alt =
    "Oh givré de la cuisine, nous t'accueillons chaleureusement dans la communauté Chefclub"

  return isCompact ? (
    <StaticImage
      className={css.banner}
      alt={alt}
      src={"banner-ice-cream-compact.png"}
      layout="fullWidth"
    />
  ) : (
    <StaticImage
      className={css.banner}
      alt={alt}
      src={"banner-ice-cream-regular.png"}
      layout="fullWidth"
    />
  )
}

export default CommunityFeedIceCreamBanner
