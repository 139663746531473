import React from "react"

import { useIntl } from "react-intl"

import { BalanceText, Body, Heading } from "~/components/ui"

import css from "./CommunityFeedBanner.module.scss"

interface PropTypes {
  onClick: () => void
}

function CommunityFeedBanner({ onClick }: PropTypes) {
  const intl = useIntl()

  return (
    <div data-nosnippet onClick={onClick} className={css.banner}>
      <span className={css.cakeImage} />
      <span className={css.hatImage} />
      <span className={css.purpleStarIcon} />
      <span className={css.smallYellowStarIcon} />
      <span className={css.largeYellowStarIcon} />

      <div className={css.content}>
        <Heading className={css.title} variant="medium" color="white">
          {intl.formatMessage({ id: "community/section:share-your-recipe" })}
        </Heading>

        <BalanceText>
          {({ className }) => (
            <Body
              className={className}
              variant="body3"
              semiBold
              noMargin
              color="white"
            >
              {intl.formatMessage({ id: "community/text:share-your-recipe" })}
            </Body>
          )}
        </BalanceText>
      </div>
    </div>
  )
}

export default CommunityFeedBanner
