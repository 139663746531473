import React, { MutableRefObject, ReactNode, RefObject } from "react"

import cn from "classnames"
import { useIntl } from "react-intl"

import Link from "~/components/Link/Link"
import { Body, Col, Heading, ImageCard, Loader, Row } from "~/components/ui"
import { navigate } from "~/gatsby/navigate"
import useIsClient from "~/hooks/useIsClient"
import { ApiCommunityPost, CommunityPost } from "~/models/CommunityPost"
import { PartialRecipe } from "~/models/PartialRecipe"
import { getVerticalFromId } from "~/models/Vertical"
import pathFromFullId from "~/routes/pathFromFullId"
import { assertNever } from "~/utils/general"

import css from "./CommunitySlide.module.scss"

export interface CommunityCarouselLoaderItem {
  type: "loader"
  payload: {
    id: string
  }
}

export type CommunityCarouselItem =
  | { type: "recipe"; payload: PartialRecipe }
  | { type: "reproduction"; payload: CommunityPost }
  | { type: "comment"; payload: ApiCommunityPost }
  | CommunityCarouselLoaderItem

interface BaseSlideProps {
  index: number
  posts: CommunityCarouselItem[]
  refs: MutableRefObject<RefObject<HTMLLIElement>[]>
  imageArea?: ReactNode
  contentArea?: ReactNode
  awaitData?: boolean
}

function BaseSlide({
  index,
  posts,
  refs,
  imageArea,
  contentArea,
  awaitData,
}: BaseSlideProps) {
  return (
    <li
      className={cn(css.carouselItem, {
        [css.hasOnlyOneItem]: posts.length === 1,
      })}
      ref={refs.current[index]}
    >
      <Row>
        {awaitData ? (
          <Col width={12}>
            <Loader />
          </Col>
        ) : (
          <>
            <Col width={[12, 6]}>{imageArea}</Col>
            <Col width={[12, 6]}>{contentArea}</Col>
          </>
        )}
      </Row>
    </li>
  )
}

interface PropTypes {
  item: CommunityCarouselItem
  index: number
  posts: CommunityCarouselItem[]
  refs: MutableRefObject<RefObject<HTMLLIElement>[]>
}

function CommunitySlide({ item, index, posts, refs }: PropTypes) {
  const intl = useIntl()
  const isClient = useIsClient()

  switch (item.type) {
    case "reproduction":
      return (
        <BaseSlide
          {...{ index, posts, refs }}
          imageArea={
            <ImageCard
              imageProps={{
                src: item.payload.attachments[0].mediaThumbnail,
              }}
              disableHover
            />
          }
          contentArea={
            <Body variant="body1" color="white" className={css.message}>
              <span className={css.authorPrefix}>
                {intl.formatMessage({ id: "recipe/text:made-by" })}
              </span>
              {` `}
              <Link
                to={`profile/u`}
                params={{ id: item.payload.fromUser.username }}
                title={item.payload.fromUser.username}
                rel="nofollow"
              >
                <span className={css.author}>
                  {item.payload.fromUser.username}
                </span>
              </Link>
              {` `}
              {item.payload.message}
            </Body>
          }
        />
      )

    case "recipe":
      const recipeUrl = pathFromFullId(
        "locale/recipes/recipe",
        intl,
        item.payload
      )

      // Hack: We have several links for the user (UX) but only one for Google (SEO)
      const goToRecipe = () => {
        if (isClient) {
          navigate(recipeUrl)
        }
      }

      return (
        <BaseSlide
          {...{ index, posts, refs }}
          imageArea={
            <ImageCard
              overlay={{ type: "branded" }}
              imageProps={{
                src: item.payload.webCover,
              }}
              color={getVerticalFromId(item.payload.vertical).color}
              onClick={goToRecipe}
            />
          }
          contentArea={
            <>
              <Heading variant="small" color="white">
                {item.payload.title}
              </Heading>
              <Body
                className={css.recipeCta}
                variant="body3"
                semiBold
                color="white"
              >
                <Link
                  title={item.payload.title}
                  to="recipes/recipe"
                  params={item.payload}
                >
                  {intl.formatMessage({ id: "action:discover-the-recipe" })}
                </Link>
              </Body>
            </>
          }
        />
      )

    case "comment":
      return (
        <BaseSlide
          {...{ index, posts, refs }}
          imageArea={
            <ImageCard
              imageProps={{
                src: item.payload.attachments[0].media,
              }}
            />
          }
          contentArea={
            <>
              <Heading variant="small" color="white">
                {item.payload.title}
              </Heading>

              <Body variant="body1" color="white" className={css.message}>
                <span className={css.authorPrefix}>
                  {intl.formatMessage({ id: "recipe/text:made-by" })}
                </span>
                {` `}
                <span className={css.author}>
                  {item.payload.from_user.username}
                </span>
                <br />
                {item.payload.message.split("\n").map(line => (
                  <>
                    {line}
                    <br />
                  </>
                ))}
              </Body>
            </>
          }
        />
      )

    case "loader":
      return <BaseSlide awaitData {...{ index, posts, refs }} />

    default:
      assertNever(item)
  }
}

export default CommunitySlide
