import React, { useContext, useEffect, useState } from "react"

import cn from "classnames"
import { useIntl } from "react-intl"
import { useSelector } from "react-redux"

import { Body } from "~/components/ui"
import { ModalLoginContext } from "~/context/ModalLoginContext"
import useShowEmailValidationModal, {
  emailValidationError,
} from "~/hooks/useShowEmailValidationModal"
import { CommunityPost, UserReaction } from "~/models/CommunityPost"
import { useLikesMutation } from "~/state/api/community"
import { authSelector } from "~/state/modules/auth"

import css from "./LikeButton.module.scss"

type LikeButtonProps = {
  post: CommunityPost
}

interface State {
  count: number
  isLiked: boolean
}

function LikeButton({ post }: LikeButtonProps) {
  const [
    toggleLikeMutation,
    { isSuccess: likingSuccess, isLoading, error: likingError },
  ] = useLikesMutation()
  const { isLoggedIn } = useSelector(authSelector)
  const intl = useIntl()
  const [{ isLiked, count }, setState] = useState<State>({
    isLiked: post.userReaction === "like",
    count: post.likesCount,
  })
  const { toggleModal } = useContext(ModalLoginContext)

  useEffect(() => {
    setState({
      isLiked: post.userReaction === "like",
      count: post.likesCount,
    })
  }, [post])

  const toggleLikeState = (prevState: State): State => ({
    isLiked: !prevState.isLiked,
    count: !prevState.isLiked ? prevState.count + 1 : prevState.count - 1,
  })

  const handleModal = () => {
    toggleModal && toggleModal(intl.formatMessage({ id: "form/text:to-like" }))
  }

  const toggleLike = async () => {
    try {
      await toggleLikeMutation({
        postId: post.id,
        userReaction: (!isLiked ? "like" : "none") as UserReaction,
      })
    } catch (error) {
      // If network failed, revert prev state
      setState(toggleLikeState)
      console.error(error)
    }
    likingSuccess && setState(toggleLikeState)
  }

  // Gets triggered whenever an API error ensues from liking/unliking a community feed post
  useShowEmailValidationModal(likingError as emailValidationError)

  return (
    <>
      <button
        className={css.resetCTA}
        onClick={isLoggedIn ? toggleLike : handleModal}
        disabled={isLoading}
      >
        <div
          className={cn(css.like, {
            [css.isLiked]: isLiked,
          })}
        >
          <div className={css.likeIconWrapper}>
            <span className={css.likeIcon} />
            <span className={css.likedIcon} />
          </div>

          <Body variant="like" color={isLiked ? "inherit" : "body"}>
            {`${count} ${intl.formatMessage({ id: "action:like" })}`}
          </Body>
        </div>
      </button>
    </>
  )
}

export default LikeButton
