import React, { useCallback, useEffect, useRef, useState } from "react"

import debounce from "lodash/debounce"
import { useIntl } from "react-intl"

import CommunitySlide, {
  CommunityCarouselItem,
  CommunityCarouselLoaderItem,
} from "../CommunitySlide/CommunitySlide"
import { Body } from "~/components/ui"
import useEventListener from "~/hooks/useEventListener"
import useIsomorphicLayoutEffect from "~/hooks/useIsomorphicLayoutEffect"
import useIsOnCompact from "~/hooks/useIsOnCompact"

import css from "./CommunityCarousel.module.scss"

interface PropTypes {
  postIndex: number
  posts: CommunityCarouselItem[]
  onChange: (postId: string | null) => void
  awaitData?: boolean
  totalPostCount?: number
  onCanLoadMore?: () => void
}

// Todo: Load more api call must be here, need refactoring with Redux & RTK
function CommunityCarousel({
  postIndex,
  onChange,
  awaitData,
  totalPostCount,
  onCanLoadMore,
  ...props
}: PropTypes) {
  const intl = useIntl()
  const loaderSlide: CommunityCarouselLoaderItem = {
    type: "loader",
    payload: {
      id: Date.now().toString(),
    },
  }
  const [, setUpdate] = useState(0)
  const posts = awaitData ? [...props.posts, loaderSlide] : props.posts
  const postCount = posts.length
  const carouselRef = useRef<HTMLUListElement | null>(null)
  const refs = useRef(posts.map(() => React.createRef<HTMLLIElement>()))
  const [activeIndex, setActiveIndex] = useState(postIndex)
  const [carouselClientX, setCarouselClientX] = useState(0)
  const isCompact = useIsOnCompact()
  const [isInitialRender, setIsInitialRender] = useState(true)

  const getPostId = (post: CommunityCarouselItem) => {
    if (post.type == "comment") {
      return post.payload.uuid
    }
    return post.payload.id
  }

  const prev = () => {
    if (activeIndex > 0) setActiveIndex(prev => prev - 1)
  }

  const next = () => {
    if (activeIndex < posts.length - 1) setActiveIndex(prev => prev + 1)
  }

  const getCarouselClientX = useCallback(() => {
    if (carouselRef.current) {
      const carouselRect = carouselRef.current.getBoundingClientRect()
      setCarouselClientX(carouselRect.x || 0)
    }
  }, [carouselRef])

  // Update activeIndex on scroll/touch event
  const handleScroll = debounce(() => {
    if (refs.current) {
      refs.current.forEach((ref, index) => {
        // Wait scroll animation
        const clientRect = ref.current?.getBoundingClientRect()
        const carouselMarginX = isCompact
          ? (window.innerWidth - 250) / 2
          : carouselClientX

        if (clientRect?.x === carouselMarginX) {
          setActiveIndex(index)
        }
      })
    }
  }, 500)

  const scrollIntoView = (element: HTMLLIElement | null) => {
    if (element) {
      element.scrollIntoView({
        block: "center",
        behavior: isInitialRender ? "auto" : "smooth",
        inline: "center",
      })
    }
  }

  useIsomorphicLayoutEffect(() => {
    onChange(getPostId(posts[activeIndex]))
    scrollIntoView(refs.current[activeIndex]?.current)

    // Trigger can load more (triggered at last -2)
    if (activeIndex >= posts.length - 3) {
      setTimeout(() => onCanLoadMore && onCanLoadMore(), 500)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIndex])

  useEffect(() => {
    getCarouselClientX()
    if (isInitialRender) {
      setIsInitialRender(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Update refs if new posts are added to the carousel
  useEffect(() => {
    refs.current = posts.map(() => React.createRef<HTMLLIElement>())

    // Update ref does not re-render the component
    setUpdate(Date.now())

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postCount])

  useEventListener("scroll", handleScroll, carouselRef)

  useEventListener("resize", getCarouselClientX)

  useEventListener("keydown", (event: KeyboardEvent) => {
    if (["ArrowRight", "ArrowLeft"].includes(event.key)) {
      event.preventDefault()
      event.stopImmediatePropagation()
    }

    switch (event.key) {
      case "ArrowRight":
        next()
        break
      case "ArrowLeft":
        prev()
        break

      default:
        break
    }
  })

  return (
    <div className={css.carouselWrapper}>
      <ul ref={carouselRef} className={css.carousel}>
        {posts.map((item, index) => (
          <CommunitySlide key={index} {...{ item, index, posts, refs }} />
        ))}
      </ul>

      {activeIndex !== 0 && (
        <button
          className={css.prev}
          onClick={prev}
          title={intl.formatMessage({ id: "action:prev" })}
        />
      )}
      {activeIndex !== posts.length - 1 && (
        <button
          className={css.next}
          onClick={next}
          title={intl.formatMessage({ id: "action:next" })}
        />
      )}

      <div className={css.counter}>
        <Body variant="like" color="white" align="center">
          {activeIndex + 1} /{" "}
          {awaitData ? "..." : totalPostCount || posts.length}
        </Body>
      </div>
    </div>
  )
}

export default CommunityCarousel
